import { QualifierStatusType } from "services/dashboard.types";
import { MecpStatusType } from "services/mecp-dashboard.types";

export const getStatusText = (status: QualifierStatusType, t: any) => {
	switch (status) {
		case "InComplete":
			return t("pages.dashboard.status.incomplete");
		case "Complete":
		case "OnWaiver":
		case "LOA":
			return t("pages.dashboard.status.complete");
		case "Deferred":
			return t("pages.dashboard.status.dataComingSoon");
		case "ActionRequired":
			return t("pages.dashboard.status.incomplete");
		default:
			return "";
	}
};

export const getMecpStatusText = (status: MecpStatusType, t: any) => {
	switch (status) {
		case "Complete":
			return t("pages.mecpDashboard.status.complete");
		case "InComplete":
			return t("pages.dashboard.status.incomplete");
		case "Achieved":
			return t("pages.mecpDashboard.status.achieved");
		case "NotAchieved":
			return t("pages.mecpDashboard.status.notAchieved");
		case "Deferred":
			return t("pages.dashboard.status.dataComingSoon");
		default:
			return "";
	}
};

export const getMecpStatusIcon = (status: MecpStatusType) => {
	switch (status) {
		case "Complete":
		case "Achieved":
			return { icon: "/assets/icons/meDDicon/icon-complete.svg", color: "green" };
		case "InComplete":
		case "NotAchieved":
			return { icon: "/assets/icons/meDDicon/icon-not-complete.svg", color: "orange" };
		case "Deferred":
			return { icon: "/assets/icons/meDDicon/icon-deferred.svg", color: "gray" };
		default:
			return { icon: "", color: "" };
	}
};

export const getSecondaryStatusText = (status: QualifierStatusType, t: any, countryCode: string) => {
	switch (status) {
		case "LOA":
			return t("pages.dashboard.status.pendingTermsOfLOA");
		case "OnWaiver":
			return t("pages.dashboard.status.waiver");
		case "ActionRequired":
			return "";
		default:
			return "";
	}
};

export const getSecondaryHighlightText = (status: QualifierStatusType, t: any, attestationId: string, attestationStatus: string) => {
	if (attestationId) return attestationStatus;
	switch (status) {
		case "ActionRequired":
			return t("pages.dashboard.status.takeActionBy");
		default:
			return "";
	}
};

export const getLinkText = (status: QualifierStatusType, t: any, countryCode: string) => {
	const waiverText = countryCode === "CAN" ? t("pages.dashboard.status.waiver") : t("pages.dashboard.documentLink.waiver");
	switch (status) {
		case "LOA":
			return t("pages.dashboard.documentLink.loa");
		case "OnWaiver":
			return waiverText;
		default:
			return "";
	}
};

export const getLinkExpiryText = (status: QualifierStatusType, expiry: string, t: any) => {
	switch (status) {
		case "LOA":
			return `${t("pages.dashboard.documentLink.loaDate")}: ${expiry}`;
		case "OnWaiver":
			return `${t("pages.dashboard.documentLink.waiverDate")}: ${expiry}`;
		default:
			return expiry;
	}
};
