import { BaseQueryFn, fetchBaseQuery } from "@reduxjs/toolkit/query";
import { getAccessToken, getTokenAuthDetails } from "providers/AuthProvider";

/**
 * Interface defining the configuration options for the base query.
 */
interface BaseQueryConfig {
	includeCountryCode?: boolean;
	includeLanguage?: boolean;
	includeApiKey?: boolean;
	includeMmedAccessToken?: boolean;
	includeWslId?: boolean;
	includeDocServiceApiKey?: boolean;
	includeGatewayApiKey?: boolean;
}

/**
 * Creates a customized base query for RTK Query with additional headers.
 *
 * @param {Object} params - Parameters for creating the base query.
 * @param {string} params.baseUrl - The base URL for the API.
 * @param {BaseQueryConfig} params.config - Configuration options for the base query.
 * @returns {Function} - A customized base query function.
 *
 * @example
 * // Usage example in a Redux Toolkit slice:
 * import { createApi } from '@reduxjs/toolkit/query/react';
 * import { createBaseQuery } from './path/to/createBaseQuery';
 *
 * const api = createApi({
 *   reducerPath: 'api',
 *   baseQuery: createBaseQuery({
 *     baseUrl: 'https://api.example.com',
 *     config: {
 *       includeApiKey: true,
 *       includeMmedAccessToken: true,
 *     },
 *   }),
 *   endpoints: (builder) => ({
 *     getExample: builder.query({
 *       query: () => '/example-endpoint',
 *     }),
 *   }),
 * });
 */
export const createBaseQuery = ({ baseUrl, config }: { baseUrl: string; config: BaseQueryConfig }): BaseQueryFn => {
	return fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers) => {
			const { wslId, countryCode, preferredLanguage } = getTokenAuthDetails();

			headers.set("Accept", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json");

			if (config.includeCountryCode) {
				headers.set("countryCode", countryCode);
			}
			if (config.includeLanguage) {
				headers.set("preferredLanguage", preferredLanguage);
			}

			if (config.includeApiKey && process.env.REACT_APP_GATEWAY_API_KEY) {
				headers.set("apikey", process.env.REACT_APP_GATEWAY_API_KEY);
				headers.set("x-api-key", process.env.REACT_APP_GATEWAY_API_KEY);
			}

			if (config.includeDocServiceApiKey) {
				headers.set("apikey", process.env.REACT_APP_DOCUMENT_SERVICE_API_KEY ?? "");
			}

			if (config.includeGatewayApiKey) {
				headers.set("x-api-key", "AIzaSyDo4eNU_XV1MkuUMvrVKrH2mPS3WlaICmo");
			}

			if (config.includeMmedAccessToken) {
				headers.set("MMED_ACCESS_TOKEN", `${getAccessToken()}`);
			}

			if (config.includeWslId) {
				headers.set("wslId", wslId);
			}

			return headers;
		},
	});
};
